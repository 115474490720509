import React, { useState } from 'react';
import { teethData } from '../../../data';

// Tooth Component
const Tooth = ({ id, upperImg, frontImg, onSelect, isSelected }) => {
  return (
    <div
      className={`cursor-pointer p-2 border ${isSelected ? 'border-blue-700 border-2' : 'border-gray-300 border-2'} rounded-lg hover:border-blue-700 transition duration-300`}
      onClick={() => onSelect(id)} // This will toggle the selection
      title={isSelected ? 'Deselect Tooth' : 'Select Tooth'} // Tooltip to indicate whether the tooth is selected or not
    >
      <div className="flex flex-col items-center">
        <img src={upperImg} alt={`Tooth ${id} upper`} className="w-16 h-16 object-contain mb-2" />
        <img src={frontImg} alt={`Tooth ${id} front`} className="w-16 h-16 object-contain mb-2" />
        <div className="bg-gray-100 p-1 rounded-full">
          <span className="text-sm font-semibold text-gray-700">{id}</span>
        </div>
      </div>
    </div>
  );
};

// ToothGrid Component
const ToothGrid = ({ selectedTeeth, setSelectedTeeth }) => {
  const [reasonModalOpen, setReasonModalOpen] = useState(false);
  const [selectedToothId, setSelectedToothId] = useState(null);
  const [reason, setReason] = useState('');

  // Handle the selection/deselection of a tooth and open the modal
  const handleSelectTooth = (id) => {
    const selectedTooth = selectedTeeth.find((tooth) => tooth.id === id);

    if (selectedTooth) {
      // If the tooth is already selected, pre-fill the reason field
      setSelectedToothId(id);
      setReason(selectedTooth.reason || ''); // Pre-fill the reason if it exists
    } else {
      // If the tooth is not selected, prepare for adding a reason
      setSelectedToothId(id);
      setReason(''); // Clear the reason field for new selection
    }

    setReasonModalOpen(true); // Open the modal for entering or updating the reason
  };

  // Handle the reason input change
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  // Save or update the reason for the selected tooth
  const handleSaveReason = () => {
    setSelectedTeeth((prevSelected) => {
      const updatedSelectedTeeth = [...prevSelected];
      const toothIndex = updatedSelectedTeeth.findIndex((tooth) => tooth.id === selectedToothId);

      // If the tooth is already selected, update the reason
      if (toothIndex >= 0) {
        updatedSelectedTeeth[toothIndex] = { id: selectedToothId, reason };
      } else {
        // If not selected, add the tooth with the reason
        updatedSelectedTeeth.push({ id: selectedToothId, reason });
      }

      return updatedSelectedTeeth;
    });

    // Close the modal and reset the reason
    setReasonModalOpen(false);
    setReason('');
  };

  // Remove the selected tooth
  const handleRemoveTooth = () => {
    setSelectedTeeth((prevSelected) => {
      return prevSelected.filter((tooth) => tooth.id !== selectedToothId);
    });

    // Close the modal
    setReasonModalOpen(false);
    setReason('');
  };

  // Function to render the teeth components
  const renderTeeth = (teeth) => {
    return teeth.map((tooth) => (
      <td key={tooth.id} className="p-1">
        <Tooth
          id={tooth.id}
          upperImg={tooth.upperImg}
          frontImg={tooth.frontImg}
          onSelect={handleSelectTooth}
          isSelected={selectedTeeth.some((selectedTooth) => selectedTooth.id === tooth.id)} // Check if the tooth is selected
        />
      </td>
    ));
  };

  return (
    <div className="w-full overflow-x-auto">
      {/* Upper Teeth */}
      <div className="min-w-max">
        <table className="w-full border-collapse">
          <tbody>
            <tr>
              <td className="border-r-4 border-b-2 border-green-300">
                <table className="w-full border-collapse">
                  <tbody>
                    <tr>{renderTeeth(teethData.upperLeftTeeth)}</tr>
                  </tbody>
                </table>
              </td>
              <td className="border-l-2 border-b-2 border-green-300">
                <table className="w-full border-collapse">
                  <tbody>
                    <tr>{renderTeeth(teethData.upperRightTeeth)}</tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Lower Teeth */}
      <div className="min-w-max">
        <table className="w-full border-collapse">
          <tbody>
            <tr>
              <td className="border-r-4 border-t-2 border-green-300">
                <table className="w-full border-collapse">
                  <tbody>
                    <tr>{renderTeeth(teethData.lowerLeftTeeth)}</tr>
                  </tbody>
                </table>
              </td>
              <td className="border-t-2 border-green-300">
                <table className="w-full border-collapse">
                  <tbody>
                    <tr>{renderTeeth(teethData.lowerRightTeeth)}</tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Reason Modal */}
      {reasonModalOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-4 rounded-lg shadow-lg w-80">
            <h3 className="text-lg font-semibold mb-4">
              {selectedTeeth.some((tooth) => tooth.id === selectedToothId) 
                ? `Edit Reason for Tooth ${selectedToothId}` 
                : `Provide a Reason for Tooth ${selectedToothId}`}
            </h3>
            <textarea
              className="w-full p-2 border rounded-md"
              rows="4"
              value={reason}
              onChange={handleReasonChange}
              placeholder="Enter reason here"
            />
            <div className="mt-4 flex justify-between">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
                onClick={() => setReasonModalOpen(false)}
              >
                Cancel
              </button>
              {selectedTeeth.some((tooth) => tooth.id === selectedToothId) ? (
                <><button
                  className="px-4 py-2 bg-red-500 text-white rounded-md"
                  onClick={handleRemoveTooth} // Remove the tooth from the selected list
                >
                  Remove
                </button>
                <button
                className="px-4 py-2 bg-blue-500 text-white rounded-md"
                onClick={handleSaveReason} // Save the reason for the selected tooth
              >
                Save
              </button>
              </>
              ) : (
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded-md"
                  onClick={handleSaveReason} // Save the reason for the selected tooth
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ToothGrid;
